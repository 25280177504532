export enum AuditOperation {
  CREATE='CREATE',
  UPDATE='UPDATE',
  DELETE='DELETE',
}

export interface AuditLog {
  resourceName: string;
  resourceId: string;
  operation: AuditOperation;
  message: string;
  performerName: string;
  performerEmail: string;
}

export interface AuditLogData extends AuditLog {
  createdAt: string
  id: string
}
