import { createSlice } from '@reduxjs/toolkit';
import { ICarePlan } from '../interfaces/carePlan';

type CarePlanStateType = {
  carePlans: ICarePlan[];
  isAddCarePlan: boolean;
  isEditCarePlan: boolean;
  showCarePlanEnrollmentModal: boolean;
  modalType: string;
};

const initialState: CarePlanStateType = {
  carePlans: [],
  isAddCarePlan: false,
  isEditCarePlan: false,
  showCarePlanEnrollmentModal: false,
  modalType: '',
};

const carePlanSlice = createSlice({
  name: 'carePlan',
  initialState,
  reducers: {
    updateCarePlans: (state, { payload }) => {
      state.carePlans = payload;
    },
    openAddCarePlanForm: (state) => {
      state.isAddCarePlan = true;
    },
    openEditCarePlanForm: (state) => {
      state.isEditCarePlan = true;
    },
    closeCarePlanForm: (state) => {
      state.isAddCarePlan = false;
      state.isEditCarePlan = false;
    },

    openCarePlanEnrollmentModal: (state) => {
      state.showCarePlanEnrollmentModal = true;
    },
    closeCarePlanEnrollmentModal: (state) => {
      state.showCarePlanEnrollmentModal = false;
    },

    setModalType: (state, { payload }) => {
      state.modalType = payload;
    },

    clearState: () => {
      return initialState;
    },
  },
});

export const {
  updateCarePlans,
  openAddCarePlanForm,
  openEditCarePlanForm,
  closeCarePlanForm,
  openCarePlanEnrollmentModal,
  closeCarePlanEnrollmentModal,
  setModalType,
  clearState,
} = carePlanSlice.actions;
export default carePlanSlice.reducer;
