import { useEffect } from 'react';
import { useAuth } from 'src/common/contexts/auth/AuthProvider';
import LoadingSpinner from 'src/common/components/loading/LoadingSpinner';
import { useSession, signIn, signOut } from 'next-auth/react';

export function AuthGuard({ children }: { children: JSX.Element }) {
  const { user, initializing } = useAuth();
  const { status, data: session } = useSession({
    required: true,
    onUnauthenticated() {
      signIn('keycloak');
    },
  }) as any;

  //We had noticed a behavior where the token expires before user session
  // As a result, users request to the EHR /demography server and other services returns 401
  // because of expired token.
  // To address this issue, we will force sign out if the session returns a RefreshAccessTokenError
  useEffect(() => {
    if (session?.error) {
      signOut();
    }
  },[session?.error]);

  if (status === 'loading') {
    return (
      <div className="loading-container d-flex flex-column align-items-center justify-content-center">
        <LoadingSpinner />
      </div>
    );
  }

  /* show loading indicator while the auth provider is still initializing */
  if (initializing) {
    return (
      <div className="loading-container d-flex flex-column align-items-center justify-content-center ">
        <LoadingSpinner />
      </div>
    );
  }

  // if auth initialized with a valid user show protected page
  if (!initializing && user) {
    return <>{children}</>;
  }

  /* otherwise don't return anything, will do a redirect from useEffect */
  return null;
}
