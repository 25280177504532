import { getSession } from 'next-auth/react';

import { AuditLog, AuditOperation } from '@interfaces/auditLog';
import { ServiceContext } from '@interfaces/serviceContext';
import { ISession } from '@interfaces/session';

export const prepareAuditLogData = async (context: ServiceContext): Promise<AuditLog> => {
  let message = '';
  const { resourceName, operation, resourceId } = context;
  const session = await getSession() as unknown as ISession['data'];
  
  switch (operation) {
    case AuditOperation.CREATE:
      message = `${resourceName} Created`;
      break;
    case AuditOperation.UPDATE:
      message = `${resourceName} Updated`;
      break;
    case AuditOperation.DELETE:
      message = `${resourceName} Deleted`;
      break;
    default:
      message = `${resourceName} Changed`;
  }

  return {
    resourceName,
    resourceId: resourceId!,
    operation: operation!,
    performerName: session?.user?.name ?? 'Unknown User',
    performerEmail: session?.user?.email ?? 'Unknown Email',
    message,
  };
};
